import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import showdown from 'showdown';

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function ImprintPage() {
  const { strapiImprint } = useStaticQuery(
    graphql`
      query imprintDataQuery {
        strapiImprint {
          PageContent
          PageDescription
          PageSubtitle
          PageTitle
          updated_at
        }
      }
    `
  );

  const getHtmlFromMarkdown = (markdown) => {
    const converter = new showdown.Converter();
    return converter.makeHtml(markdown);
  }

  return(
  <Layout>
    <SEO title={strapiImprint.PageTitle} />
    <h1>{strapiImprint.PageTitle}</h1>
    <p>{strapiImprint.PageSubtitle}</p>
    <div dangerouslySetInnerHTML={{ __html: getHtmlFromMarkdown(strapiImprint.PageContent) }}></div>
  </Layout>
  );
}

export default ImprintPage